
import moment from "moment";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import useCustomers from "@/composables/useCustomers";
import useTerritories from "@/composables/useTerritories";
import useOrders from "@/composables/useOrders";
import { useRoute } from "vue-router";
import usePriceList from "@/composables/usePriceList";
import { PriceListFilter } from "@/models";
import {
  CreateOrEditCustomerTerritoryDto,
  CustomerDto,
  IGetCustomerForViewDto,
  IGetCustomerTerritoryForViewDto,
  VolumeClassDto,
} from "@/shared/service-proxies/service-proxies";
import { TerritoriesFilter } from "@/models";
import DeleteItemService from "@/core/services/DeleteItemService";
import { formatCurrency } from "@/core/services/HelperServices";

export default defineComponent({
  setup() {
    const {
      getCustomer,
      customer,
      customerTerritories,
      updateOrCreateCustomerTerritory,
      getCustomerTerritories,
      deleteCustomerTerritory,
    } = useCustomers();
    const { getTerritories, territories } = useTerritories();
    const { getOrderHistory, orderHistory } = useOrders();
    const route = useRoute();
    const loaded = ref(false);
    const dataId = route.params.id as unknown as string;
    const breadcrumbs = {
      title: "Customer",
      breadcrumbs: ["View"],
    };

    const modelValue = ref<IGetCustomerForViewDto>({
      customer: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        outletName: "",
        outletAddress: "",
        landmark: "",
        retailChannel: "",
        region: "",
        state: "",
        lga: "",
        territoryId: "",
        loyaltyPoint: "",
        urno: "",
        rcS8Number: "",
        priceListId: "",
        id: "",
      } as unknown as CustomerDto,
      volumeClass: {
        name: "",
        description: "",
      } as unknown as VolumeClassDto,
      territoryName: "",
    });

    const customerTerritory = ref<Array<IGetCustomerTerritoryForViewDto>>([]);

    const selectedTerritory = ref<string>("");
    const { priceLists, getPriceLists } = usePriceList();
    const defaultPriceListFilter = ref({
      sorting: null,
      skipCount: 0,
      maxResultCount: 1000,
    } as unknown as PriceListFilter);

    const getPriceListData = async () => {
      await getPriceLists(
        defaultPriceListFilter.value as unknown as PriceListFilter
      );
      console.log(priceLists);
    };

    onMounted(async () => {
      await getPriceListData();
      const defaultTerritoryFilter = ref({
        filter: null,
        territoryCodeFilter: null,
        territoryNameFilter: null,
        territoryDescriptionFilter: null,
        sorting: null,
        skipCount: 0,
        maxResultCount: 1000,
      } as unknown as TerritoriesFilter);
      setCurrentPageBreadcrumbs("Customer Details", ["Apps", "Customers"]);

      await getCustomer(dataId);

      await getCustomerTerritories(dataId);

      await getOrderHistory(dataId);

      modelValue.value = customer.value;

      customerTerritory.value = customerTerritories.value;

      await getTerritories(
        defaultTerritoryFilter.value as unknown as TerritoriesFilter
      );
    });

    const addCustomerTerritory = async () => {
      const res = await updateOrCreateCustomerTerritory({
        customerId: dataId,
        territoryId: selectedTerritory.value,
      } as unknown as CreateOrEditCustomerTerritoryDto);

      if (res) {
        await getCustomerTerritories(route.params.id as unknown as string);
        customerTerritory.value = customerTerritories.value;
        selectedTerritory.value = "";
      }
    };

    const deleteCustomerTerritoryHandler = async (id: string) => {
      const res = await DeleteItemService.delete(
        id as string,
        deleteCustomerTerritory
      );

      if (res) {
        for (let i = 0; i < customerTerritories.value.length; i++) {
          if (customerTerritories.value[i].customerTerritory.id === id) {
            customerTerritories.value.splice(i, 1);
          }
        }
      }
    };

    return {
      priceLists,
      modelValue,
      loaded,
      customerTerritory,
      territories,
      selectedTerritory,
      addCustomerTerritory,
      orderHistory,
      deleteCustomerTerritoryHandler,
      formatCurrency,
      moment,
      breadcrumbs,
    };
  },
});
